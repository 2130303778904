import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './like-icons.css';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'aos/dist/aos.css';
import AOS from 'aos/dist/aos';

console.log('TARGET: ' + process.env.REACT_APP_BUILD_TARGET);

function importBuildTarget() { 
    if(process.env.REACT_APP_BUILD_TARGET === "eichefe") { 
        return import('./eichefe/App'); 
    } else if (process.env.REACT_APP_BUILD_TARGET === "projetonfe") { 
        return import('./projetonfe/App'); 
    } else { 
        return Promise.reject(
            new Error("No such build target: " + process.env.REACT_APP_BUILD_TARGET)
        ); 
    }
}

importBuildTarget().then(({ default: Environment }) => 
    ReactDOM.render( 
        <React.StrictMode> 
            <Environment /> 
        </React.StrictMode>
    , document.getElementById("root")
    )
);

serviceWorker.unregister();
AOS.init({
    delay: 150,
    offset: 300
});
